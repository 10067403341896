angular.module('fingerink')
    .factory('contactService', ['$http', 'session', function ($http, session) {
        var sdo = {
            getContactoss: function (params) {
                return $http({
                    method: 'GET',
                    url: 'contacts' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            
            getContactosGrupos: function (params) {
                return $http({
                    method: 'GET',
                    url: 'contacts/groups' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },  
            
            
            addContactoGrupo: function (contact) {
                return $http({
                    method: 'POST',
                    url: 'contacts/groups',
                    crossDomain: true,
                    data: contact
                });
            },
            
            updateContactoGrupo: function (grupo) {
                return $http({
                    method: 'PUT',
                    url: 'contacts/groups/' + grupo.id,
                    crossDomain: true,
                    data: grupo
                });
            },            
            
            deleteContactoGrupo: function (id) {
                return $http({
                    method: 'DELETE',
                    url: 'contacts/groups/' + id,
                    crossDomain: true
                });
            },            
            
            addContact: function (contact) {
                return $http({
                    method: 'POST',
                    url: 'contacts',
                    crossDomain: true,
                    data: contact
                });
            },
            
            deleteContact:function (id) {
                return $http({
                    method: 'DELETE',
                    url: 'contacts/' + id,
                    crossDomain: true
                });
            }
        };
        return sdo;
}]);
